import axios from "axios";
import { InstagramFullTokenResponse } from '@/types/requests/instagram';
import { InstagramAuthLoginConfig } from '@/types/front-data-type/instagram/instagramAuthLoginConfig';

export default async function (
    accessToken: string,
    loginConfig: InstagramAuthLoginConfig
): Promise<InstagramFullTokenResponse | false> {
    try {
        const url = loginConfig.fullToken.url;

        const params = new URLSearchParams()
        params.append('grant_type', loginConfig.fullToken.grantType)
        params.append('client_secret', loginConfig.fullToken.clientSecret ? loginConfig.fullToken.clientSecret : '')
        params.append('client_id', loginConfig.fullToken.clientId ? loginConfig.fullToken.clientId : '')
        params.append('access_token', accessToken)

        const response = await axios.get<InstagramFullTokenResponse>(url,
            {
                params,
                headers: {
                    'content-type': 'application/x-www-form-urlencoded'
                }
            }
        );

        return response.data;
    } catch (e) {
        return false;
    }
}
