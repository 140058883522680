import { defaultFile, FileType } from '@/types/base/file';
import { UserType } from '@/types/user';
import clone from '@/helpers/clone';

export type DocumentType = {
    id?: string;
    title: string;
    isTemplate: boolean;
    full_description: string;
    document_url: string;
    importId: string;
    description: string;
    document: FileType;
    image: FileType;
    fullText: string;
    created_at: string;
    updated_at: string;
    author: string;
    static_created_date: string;
    static_updated_date: string;
    isBook: boolean;
    subAuthors: UserType[];
    owner?: UserType;
    proceedings: FilterType[];
    industries: FilterType[];
    tags: FilterType[];
    material: FilterType;
    type: FilterType;
    project: FilterType;
    language: FilterType;
    year: string;
    chosenByUsers?: { id: string }[];
};

export type LikeViewCRequestType = {
    updateDocument: DocumentType;
};

export type FilterType = {
    id?: string;
    name: string;
};

export type GetDocumentsRequest = {
    allDocuments: DocumentType[];
    _allDocumentsMeta: {
        count: number;
    };
};

export type CreateDocumentRequest = {
    createDocument: DocumentType;
};

export const defaultFilter: FilterType = {
    name: '',
};

export const defaultDoc: DocumentType = {
    title: '',
    importId: '',
    document_url: '',
    static_created_date: '',
    fullText: '',
    isTemplate: false,
    static_updated_date: '',
    updated_at: '',
    created_at: '',
    description: '',
    full_description: '',
    document: clone(defaultFile),
    image: clone(defaultFile),
    author: '',
    isBook: false,
    subAuthors: [],
    proceedings: [],
    industries: [],
    tags: [],
    material: clone(defaultFilter),
    type: clone(defaultFilter),
    project: clone(defaultFilter),
    language: clone(defaultFilter),
    year: '',
    chosenByUsers: []
};
