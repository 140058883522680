






















































import Slick from 'vue-slick';
import { Component, Vue } from 'vue-property-decorator';
import LastEditions from '@/components/layouts/LastEditions.vue';
import graphql from '@/plugins/graphql';
import PostById from '@/graphql/Posts/postById.graphql';
import PostsByEdition from '@/graphql/Posts/postsByEdition.gql';
import clone from '@/helpers/clone';
import {
    defaultPost,
    PostErrorReportType,
    PostRequestType,
    PostsRequestType,
    PostType,
} from '@/types/post';

import { endpoint } from '@/helpers/endpoint';
import PostsSlider from '@/components/layouts/PostsSlider.vue';
import { Getter } from 'vuex-class';
import { UserType } from '@/types/user';
import slideshow from 'vue-gallery-slideshow';
import ArPostHeader from '@/components/layouts/post/ArPostHeader.vue';
import ArPostFooter from '@/components/layouts/post/ArPostFooter.vue';
import TextErrorReportButton, {
    ReportData,
} from '@/components/TextErrorReportButton.vue';
import sendReport from '@/graphql/PostErrorReports/sendReport.gql';

@Component({
    components: {
        TextErrorReportButton,
        ArPostFooter,
        ArPostHeader,
        PostsSlider,
        LastEditions,
        Slick,
        slideshow,
    },
})
export default class Post extends Vue {
    private endpoint = endpoint;
    private id = this.$route.params.postId;
    private post = clone<PostType>(defaultPost);
    private thisEditionPosts: PostType[] = [];
    private index: number | null = null;

    @Getter('user/getUser')
    private user: UserType;

    $refs!: {
        slider: Slick;
    };

    async errorReported(data: ReportData): Promise<void> {
        console.log(data);
        const res = await graphql<PostErrorReportType>(sendReport, {
            text: data.text,
            message: data.desc,
            postID: this.post.id,
        });
        if (res && res.createPostErrorReport && res.createPostErrorReport.id) {
            alert('Спасибо! Мы обязательно исправимся');
        }
    }

    get galleriesImagesList(): string[] {
        const result: string[] = [];
        for (const gallery of this.post.galleries) {
            const imagesUrls = gallery.images.map((image) => {
                return this.endpoint + image.image.publicUrl;
            });
            result.push(...imagesUrls);
        }
        return result;
    }

    openImage(url: string): void {
        this.index = this.galleriesImagesList.indexOf(url);
    }

    next(): void {
        this.$refs.slider.next();
    }

    prev(): void {
        this.$refs.slider.prev();
    }

    async loadPost(): Promise<void> {
        const postRequest = await graphql<PostRequestType>(PostById, {
            id: this.id,
        });
        if (postRequest) {
            this.post = postRequest.Post;

            const otherEditionsRequest = await graphql<PostsRequestType>(
                PostsByEdition,
                { id: this.post.edition.id, postID: this.post.id }
            );
            if (otherEditionsRequest) {
                this.thisEditionPosts = otherEditionsRequest.allPosts;
            }
        }

        console.log({ post: this.post });
    }

    async created(): Promise<void> {
        window.scrollTo(0, 0);
        await this.loadPost();
    }
}
