var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('button',{style:({
        position: 'absolute',
        top: _vm.reportPosition.y,
        left: _vm.reportPosition.x,
        display: _vm.showButton ? 'block' : 'none',
        backgroundColor: 'whitesmoke',
        zIndex: 9999999,
        borderRadius: '5px',
    }),on:{"click":_vm.showPrompt}},[_c('img',{staticStyle:{"width":"32px","height":"32px","padding":"2px","object-fit":"contain"},attrs:{"alt":_vm.altText,"src":"/img/icon/report.png"}})])}
var staticRenderFns = []

export { render, staticRenderFns }