
































































































































import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import { UserType } from '@/types/user';
import { endpoint } from '@/helpers/endpoint';
import moment from 'moment';
import graphql from '@/plugins/graphql';
import DateTimePicker from '@/components/DateTimePicker.vue';
import GetLikedPosts from '@/graphql/Users/getLikedPosts.gql';
import GetDocsByFilter from '@/graphql/Lab/GetDocsByFilters.gql';
import UpdateUser from '@/graphql/Users/updateUser.gql';
import { PostsRequestType, PostType } from '@/types/post';
import ARGetTogether from '@/components/layouts/profile/ARGetTogether.vue';
import { DocumentType, GetDocumentsRequest } from '@/types/document';
import BooksSlider from '@/components/layouts/BooksSlider.vue';
import Slick from 'vue-slick';
import FullDocs from '@/components/layouts/profile/full-docs.vue';
import ArDownloadMore from '@/components/form/ar-download-more.vue';

@Component({
    components: {
        ArDownloadMore,
        FullDocs,
        ARGetTogether,
        DateTimePicker,
        BooksSlider,
        Slick,
    },
})
export default class Profile extends Vue {
    @Getter('user/getUser')
    private user: UserType;
    @Action('user/loadUser')
    private loadUser: () => void;

    private endpointUrl = endpoint;
    private showItems = 3;
    private addItems = 3;
    private likedPosts: PostType[] = [];
    private chosenBooks: DocumentType[] = [];
    private chosenDocs: DocumentType[] = [];
    private docsCount = 0;
    private docsLoadedCount = 0;

    private formatDate(date: string): string {
        return moment(date).format('DD MMMM YYYY, HH:mm');
    }

    private async likedByUser(): Promise<void> {
        if (this.user) {
            const res = await graphql<PostsRequestType>(GetLikedPosts, {
                userId: this.user.id,
            });
            if (res && res.allPosts) {
                this.likedPosts = res.allPosts;
            }
        }
    }

    private async getUserBooks(): Promise<void> {
        if (this.user) {
            const resBooks = await graphql<GetDocumentsRequest>(
                GetDocsByFilter,
                {
                    where: {
                        isBook: true,
                        chosenByUsers_some: { id: this.user.id },
                    },
                }
            );
            if (resBooks && resBooks.allDocuments) {
                this.chosenBooks = resBooks.allDocuments;
            }
        }
    }

    private async getUserDocs(): Promise<void> {
        if (this.user) {
            const resDocs = await graphql<GetDocumentsRequest>(
                GetDocsByFilter,
                {
                    where: {
                        isBook: false,
                        chosenByUsers_some: { id: this.user.id },
                    },
                    skip: this.docsLoadedCount,
                    perPage: 3,
                }
            );
            if (resDocs && resDocs.allDocuments) {
                this.chosenDocs = [...this.chosenDocs, ...resDocs.allDocuments];
                console.log('count', resDocs._allDocumentsMeta.count);
                this.docsCount = resDocs._allDocumentsMeta.count;
                this.docsLoadedCount += resDocs.allDocuments.length;
            }
        }
    }

    get avatar(): string {
        if (this.user && this.user.avatar) {
            return `${this.endpointUrl}${this.user.avatar.publicUrl}`;
        }
        return 'img/profile/profile-img.jpg';
    }

    async created(): Promise<void> {
        if (!this.user) {
            await this.loadUser();
        }
        await this.getUserDocs();
        await this.getUserBooks();
        console.log({
            books: this.chosenBooks,
            docs: this.chosenDocs,
        });
        await this.likedByUser();
    }

    async changeAvatar(data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files[0]) {
            console.log('SETTING');
            const res = await this.$apollo.mutate({
                mutation: UpdateUser,
                variables: {
                    userId: this.user.id,
                    data: {
                        avatar: target.files[0],
                    },
                },
                context: {
                    hasUpload: true,
                },
            });
            console.log(333, res);
            if (res && res.data) {
                await this.loadUser();
            }
        }
    }
}
