import Vue from 'vue';
import Vuex from 'vuex';
import appState, { AppState } from '@/store/modules/appState.ts';
import user, { UserState } from "@/store/modules/user";

Vue.use(Vuex);

type MyStore = {
  appState: AppState;
  user: UserState
};

export default new Vuex.Store<MyStore>({
  modules: {
    appState,
    user
  },
});
