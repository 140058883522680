import axios from "axios";

export default async function <ReturnType>(
  code: string
): Promise<ReturnType | false> {
  try {
    const url = 'https://graph.microsoft.com/v1.0/me'
    const response = await axios.get(url, {
      headers: {
        Authorization: `Bearer ${code}`
      }
    });
    console.log('resp', response)

    return  response.data
  } catch (e) {
    console.log('error', e.response)
    return false
  }
}