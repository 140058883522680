















import { Component, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import { AuthUserRequestType, UserType } from "@/types/user";
import graphql from "@/plugins/graphql";
import authUser from "@/graphql/Users/authUser.gql";



@Component({})
export default class GalleryLogin extends Vue {
    @Mutation('appState/SET_NAVBAR_STATUS')
    private setNavStatus: (status: boolean) => void;
    @Mutation('user/SET_USER')
    private setUser: (user: UserType) => void;
    @Getter('appState/getNavbarStatus')
    private navStatus: boolean;
    @Getter('user/getUser')
    private getUser: UserType;

    private password = ''
    private email = ''

    async login (): Promise<void> {
        if (this.password.length < 8 || this.email.length < 3) {
            alert('Заполните все поля.')
        } else {
            const res = await graphql<AuthUserRequestType>(authUser, {
                email: this.email, password: this.password
            })
            if (res && res.authenticate && res.authenticate.item.isAdmin) {
                localStorage.setItem('galleryAuthToken', res.authenticate.token)
                this.$router.push('/gallery')
            } else {
                alert('Вы не авторизованы, либо у вас недостаточно прав для доступа к данном разделу.')
            }
        }
    }

    async created(): Promise<void> {
        this.setNavStatus(false)
        if (localStorage.getItem('galleryAuthToken')) {
            this.$router.push('/gallery')
        }
    }
}
