import { ActionContext } from 'vuex';

export type AppState = {
    showNavbar: boolean
};

export default {
    namespaced: true,
    state: {
        showNavbar: true
    },
    mutations: {
        SET_NAVBAR_STATUS: (state: AppState, payload: boolean): void => {
            state.showNavbar = payload;
        },
    },
    actions: {
        changeNavStatus(
          { commit }: ActionContext<AppState, unknown>, status: boolean
        ): void {
            commit('SET_NAVBAR_STATUS', {status})
        }
    },
    getters: {
        getNavbarStatus: (state: AppState):boolean => state.showNavbar
    },
};
