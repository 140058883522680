










import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import getToken from '@/requests/getToken';
import { randomString } from '@/helpers/utils';
import moment from 'moment';
import GetUserByToken from '@/requests/getUserByToken';
import GetUserByEmail from '@/graphql/Users/getUserByEmail.gql';
import CreateUser from '@/graphql/Users/createUser.gql';
import UpdateUser from '@/graphql/Users/updateUser.gql';
import graphql from '@/plugins/graphql';
import {
    GetUserByEmailRequestType,
    UserCreateRequestType,
    UserType,
} from '@/types/user';

@Component({})
export default class Login extends Vue {
    @Mutation('appState/SET_NAVBAR_STATUS')
    private setNavStatus: (status: boolean) => void;
    @Mutation('user/SET_USER')
    private setUser: (user: UserType) => void;
    @Getter('appState/getNavbarStatus')
    private navStatus: boolean;

    @Getter('user/getUser')
    private getUser: UserType;

    private challenge = randomString(43);
    private code = '';
    get url(): string {
        const tenant = process.env.VUE_APP_MS_TENANT;
        const clientId = process.env.VUE_APP_MS_CLIENT;
        const redirectUrl = process.env.VUE_APP_MS_REDIRECT;
        return (
            `https://login.microsoftonline.com/` +
            `${tenant}/oauth2/v2.0/authorize?` +
            `client_id=${clientId}&` +
            `response_type=code&` +
            `redirect_uri=${redirectUrl}&` +
            `response_mode=query&` +
            `scope=User.Read&` +
            `code_challenge=${this.challenge}&` +
            `code_challenge_method=plain`
        );
    }

    async created(): Promise<void> {
        this.code = this.$route.query.code as string;
        this.setNavStatus(false);
        if (this.code) {
            const tempKey = localStorage.getItem('tempKey') || ' ';
            const tokenRequest = await getToken<Record<string, any>>(
                this.code,
                tempKey
            );
            if (tokenRequest && tokenRequest.access_token) {
                localStorage.setItem('authToken', tokenRequest.access_token);
                localStorage.setItem(
                    'expireTime',
                    moment().add(1, 'hour').unix() + ''
                );
                await this.checkUser(tokenRequest.access_token);
                console.log('getStoreUser', this.getUser);
                await this.$router.push('/');
            }
        } else {
            localStorage.setItem('tempKey', this.challenge);
        }
    }

    async checkUser(token: string): Promise<void> {
        const userByToken = await GetUserByToken<Record<string, any>>(token);
        if (userByToken && userByToken.userPrincipalName) {
            const email = userByToken.userPrincipalName;
            const userRequest = await graphql<GetUserByEmailRequestType>(
                GetUserByEmail,
                { email }
            );
            if (userRequest) {
                if (userRequest.allUsers && userRequest.allUsers.length > 0) {
                    await graphql<UserCreateRequestType>(UpdateUser, {
                        userId: userRequest.allUsers[0].id,
                        data: {
                            tokens: { create: { token: token } },
                        },
                    });
                    this.setUser(userRequest.allUsers[0]);
                } else {
                    const newUser = await graphql<UserCreateRequestType>(
                        CreateUser,
                        {
                            data: {
                                name: userByToken.displayName,
                                email: userByToken.userPrincipalName,
                                position: userByToken.jobTitle,
                                tokens: {
                                    create: {
                                        token: token,
                                    },
                                },
                            },
                        }
                    );
                    if (newUser && newUser.createUser) {
                        this.setUser(newUser.createUser);
                    }
                }
            }
        }
    }
}
