












import { Component, Vue} from 'vue-property-decorator';


@Component
export default class ArHead extends Vue {
}
