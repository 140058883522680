









































import { Component, Emit, Prop, Vue } from 'vue-property-decorator';
import { defaultDoc, DocumentType } from '@/types/document';

import ArAvatar from '@/components/layouts/icons/ArAvatar.vue';
import UserListTooltip from '@/components/block/user-list-tooltip.vue';

@Component(
    {
        name: 'ArButtonDocumentChoose',
        components: {
            ArAvatar,
            UserListTooltip
        }
    }
)
export default class ArButtonDocumentChoose extends Vue {
    @Prop({ default: () => defaultDoc }) readonly document: DocumentType;
    @Prop({ default: false }) readonly isChosen: boolean;
    @Prop({ default: false }) readonly isBookCard: boolean;

    @Emit('addToChosen')
    addToChosen(): boolean {
        return true;
    }
}
