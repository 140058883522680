







import Vue from "vue";
import Component  from "vue-class-component";
import Navbar from "@/components/layouts/ArNavbar.vue";
import ArHead from "@/components/layouts/ArHead.vue";
import ArNavbar from "@/components/layouts/ArNavbar.vue";
@Component({
    components: { ArNavbar, ArHead, Navbar}
})
export default class App extends Vue {

}


