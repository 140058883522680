import axios, { AxiosError } from 'axios';
import { DocumentNode, print } from 'graphql';

const url = process.env.VUE_APP_GRAPHQL_HTTP || '';

export type GraphqlResponse<ReturnType> = {
    data: ReturnType;
    errors: Record<string, unknown>[];
};

export default async function <ReturnType>(
    query: DocumentNode,
    variables: Record<string, unknown> = {},
    hasUpload = false
): Promise<ReturnType | undefined> {
    try {
        const response = await axios.post<GraphqlResponse<ReturnType>>(
            url,
            {
                query: print(query),
                variables,
                context: {
                    hasUpload,
                },
            },
            {
                headers: {
                    authorization: `Bearer ${localStorage.getItem(
                        'galleryAuthToken'
                    )}`,
                },
            }
        );
        return response.data.data;
    } catch (e) {
        const err: AxiosError<GraphqlResponse<ReturnType>> = e;
        console.log('12', err, err.response);
        return err.response?.data.data;
    }
}
