import axios from "axios";
import {InstagramAuthLoginConfig} from "@/types/front-data-type/instagram/instagramAuthLoginConfig";
import {InstagramTokenResponse} from "@/types/requests/instagram";

export default async function (
    code: string,
    loginConfig: InstagramAuthLoginConfig
): Promise<InstagramTokenResponse | false> {
    try {
        const url = loginConfig.token.url;

        const params = new URLSearchParams()
        params.append('grant_type', 'authorization_code')
        params.append('client_id', loginConfig.token.clientId ? loginConfig.token.clientId : '')
        params.append('client_secret', loginConfig.token.clientSecret ? loginConfig.token.clientSecret : '')
        params.append('code', code)
        params.append('redirect_uri', loginConfig.token.redirectUri)

        const response = await axios.post<InstagramTokenResponse>(url,
            params,
            {
                headers: {
                    'content-type': 'application/x-www-form-urlencoded',
                }
            }
        );

        return response.data;
    } catch (e) {
        return false;
    }
}
