









































































































































































import { Component, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import slideshow from 'vue-gallery-slideshow';
import EditionQuery from '@/graphql/Editions/editionById.graphql';
import LastEdition from '@/graphql/Editions/lastEdition.graphql';
import { endpoint } from '@/helpers/endpoint';
import {
    defaultEdition,
    EditionRequestType,
    EditionsRequestTypes,
} from '@/types/edition';
import clone from '@/helpers/clone';
import graphql from '@/plugins/graphql';
import moment from 'moment';
import LastEditions from '@/components/layouts/LastEditions.vue';
import Instagram from '@/components/layouts/Instagram.vue';
import { Mutation } from 'vuex-class';


@Component({
    components: {
        Instagram,
        LastEditions,
        Slick,
        slideshow,
    },
})
export default class Edition extends Vue {
    @Mutation('appState/SET_NAVBAR_STATUS')
    private setNavStatus: (status: boolean) => void;
    private index = null;
    private endpoint = endpoint;
    private slidesCount = 0;
    private currentSlideCount = 1;
    private weeklySlickOptions = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        lazyLoad: 'progressive',
        centerMode: true,
        variableWidth: true,
        pauseOnHover: true,
        arrow: true,
    };
    private edition = clone(defaultEdition);

    $refs!: {
        slider: Slick;
    };

    get weeklyPhotos(): string[] {
        return this.edition.weeklyPhotos.map((i) => this.endpoint + i.url);
    }

    get bannerUrl(): string {
        if (
            this.edition &&
            this.edition.mainBanner &&
            this.edition.mainBanner.publicUrl
        ) {
            return (
                process.env.VUE_APP_API_ENDPOINT +
                this.edition.mainBanner.publicUrl
            );
        } else {
            return '';
        }
    }

    get id(): string {
        return this.$route.params.editionId;
    }

    handleInit(_: unknown, slick: Record<string, number>): void {
        this.slidesCount = slick.slideCount;
    }

    handleAfterChange(_: unknown, slick: Record<string, number>): void {
        this.currentSlideCount = slick.currentSlide + 1;
    }

    weeklyNext(): void {
        this.$refs.slider.next();
    }

    weeklyPrev(): void {
        this.$refs.slider.prev();
    }

    formattedDate(date: string): string {
        return moment(date).format('DD MMMM YYYY');
    }

    async created(): Promise<void> {
        this.setNavStatus(true);

        if (this.id) {
            const editionRequest = await graphql<EditionRequestType>(
                EditionQuery,
                { id: this.id }
            );
            if (editionRequest) {
                this.edition = editionRequest.Edition;
            }
        } else {
            const editionRequest = await graphql<EditionsRequestTypes>(
                LastEdition
            );
            if (editionRequest) {
                this.edition = editionRequest.allEditions[0];
            }
        }
    }
}
