import { ActionContext } from 'vuex';
import { GetUserByTokenRequestType, UserType } from '@/types/user';
import { AppState } from '@/store/modules/appState';
import GetUserByToken from '@/graphql/Users/getSystemUserByToken.gql';
import AddToChosenDocs from '@/graphql/Documents/AddToChosenDocs.gql';
import RemoveFromChosenDocs from '@/graphql/Documents/RemoveFromChosenDocs.gql';

import graphql from '@/plugins/graphql';
import { LikeViewCRequestType } from '@/types/document';

export type UserState = {
    user: UserType | null;
};

export default {
    namespaced: true,
    state: {
        user: null,
    },
    mutations: {
        SET_USER: (state: UserState, payload: UserType): void => {
            state.user = payload;
        },
    },
    actions: {
        async loadUser({
            commit,
        }: ActionContext<AppState, unknown>): Promise<void> {
            const token = localStorage.getItem('authToken') + '';
            const userRequest = await graphql<GetUserByTokenRequestType>(
                GetUserByToken,
                {
                    token,
                }
            );
            if (
                userRequest &&
                userRequest.allUsers &&
                userRequest.allUsers.length > 0
            ) {
                commit('SET_USER', userRequest.allUsers[0]);
            }
        },
        async CLICK_ON_DOCUMENT_CHOSE_BTN(
            context: ActionContext<UserState, unknown>,
            payload: { docId: string, isChosen: boolean },
        ): Promise<boolean> {
            console.log('Есть ли в избранном?', payload.isChosen);

            if (context.state.user) {
                if (!payload.isChosen) {
                    await graphql<LikeViewCRequestType>(AddToChosenDocs, {
                        id: payload.docId,
                        userId: context.state.user.id,
                    });
                    console.log('Добавили в избранное')
                    return true;
                } else {
                    await graphql<LikeViewCRequestType>(RemoveFromChosenDocs, {
                        id: payload.docId,
                        userId: context.state.user.id,
                    });
                    console.log('Убрали изи избранного')
                    return false;
                }
            }
            return false;
        },
    },
    getters: {
        getUser: (state: UserState): UserType | null => state.user,
        CHECK_IS_DOC_CHOSEN_BY_USER:
            (state: UserState) =>
                (payload: string): boolean | null | undefined => {
                    console.log(payload)
                    return (
                        state.user &&
                        state.user.chosenDocuments &&
                        !!state.user.chosenDocuments.find((item) => {
                            return item.id === payload;
                        })
                    );
                },
    },
};
