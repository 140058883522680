















































































































































































































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import moment from 'moment';
import {
    AllEvents,
    Event as AequoEvent,
    EventSubscriber,
    SubscribeOrUnsubscribeToEvent,
} from '@/types/event';
import { endpoint } from '@/helpers/endpoint';
import { UserType } from '@/types/user';
import DateTimePicker from '@/components/DateTimePicker.vue';
import graphql from '@/plugins/graphql';
import getEventsByDate from '@/graphql/Events/getUserEventsByDate.gql';
import GetAllUserEvents from '@/graphql/Events/getAllUserEvents.gql';
import unsubscribeFromEvent from '@/graphql/Events/unsubscribeFromEvent.graphql';
import subscribeToEvent from '@/graphql/Events/subscribeToEvent.graphql';
import createEvent from '@/graphql/Events/createEvent.gql';
import { CreateEvent } from '@/views/GetTogether.vue';
import TimePicker from '@/components/TimePicker.vue';
import { getDate } from '@/helpers/date';

@Component({
    name: 'ARGetTogether',
    components: { DateTimePicker, TimePicker },
})
export default class ARGetTogether extends Vue {
    @Prop() readonly user: UserType;

    private events: AequoEvent[] = [];
    private selectedDate = moment().format('YYYY-MM-DD');
    private endpointUrl = endpoint;
    private createEvent: CreateEvent = {
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        title: '',
        description: '',
        image: '',
    };

    @Watch('user', { deep: true })
    userWatcher(newVal: UserType | null): void {
        if (newVal !== null) {
            this.loadEvents();
        }
    }

    get avatar(): string {
        if (this.user && this.user.avatar) {
            return `${this.endpointUrl}${this.user.avatar.publicUrl}`;
        }
        return 'img/profile/profile-img.jpg';
    }

    private get userId(): string {
        return this.user && this.user.id ? this.user.id : '-1';
    }

    private formatDate(dt: string): string {
        return getDate(dt);
    }

    private getEventImg(event: AequoEvent) {
        if (event.image) {
            return this.endpointUrl + event.image.publicUrl;
        } else {
            return 'img/events/1.jpg';
        }
    }

    private subscribed(event: AequoEvent): boolean {
        return (
            event.users.filter((i: EventSubscriber) => {
                return i.id === this.userId;
            }).length > 0
        );
    }

    private contactAuthor(event: AequoEvent) {
        window.location.href = `mailto:${event.author.email}`;
    }

    private getEventAuthorAvatar(event: AequoEvent): string {
        if (event && event.author && event.author.avatar) {
            return `${this.endpointUrl}${event.author.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    private async getByDate(dateStr: string): Promise<void> {
        const date = moment(dateStr);
        const start = date.startOf('date').toISOString(true);
        const end = date.endOf('date').toISOString(true);
        console.log(start, end);
        const resp = await graphql<AllEvents>(getEventsByDate, {
            startDate: start,
            endDate: end,
            userId: this.user.id,
        });
        if (resp && resp.allEvents) {
            this.events = resp.allEvents;
        }
    }

    private async loadEvents(): Promise<void> {
        const resp = await graphql<AllEvents>(GetAllUserEvents, {
            now: moment().toISOString(true),
            userId: this.userId,
        });
        console.log(resp);
        if (resp && resp.allEvents) {
            this.events = resp.allEvents;
        }
    }

    private async removeFromCalendar(event: AequoEvent): Promise<void> {
        const res = await graphql<SubscribeOrUnsubscribeToEvent>(
            unsubscribeFromEvent,
            {
                eventID: event.id,
                userID: this.user.id,
            }
        );
        if (res && res.updateEvent && res.updateEvent.id) {
            this.events = this.events.map((e: AequoEvent) => {
                if (e.id === res.updateEvent.id) {
                    e.users = res.updateEvent.users;
                }
                return e;
            });
        }
    }

    private async addToCalendar(event: AequoEvent): Promise<void> {
        const res = await graphql<SubscribeOrUnsubscribeToEvent>(
            subscribeToEvent,
            {
                eventID: event.id,
                userID: this.user.id,
            }
        );
        if (res && res.updateEvent && res.updateEvent.id) {
            this.events = this.events.map((e: AequoEvent) => {
                if (e.id === res.updateEvent.id) {
                    e.users = res.updateEvent.users;
                }
                return e;
            });
        }
    }

    private async makeCreateEventRequest(): Promise<void> {
        const dt = `${this.createEvent.date} ${this.createEvent.time}`;
        const event: Record<string, any> = {
            author: { connect: { id: this.user.id } },
            title: this.createEvent.title,
            description: this.createEvent.description,
            body: 'some body',
            date_time: moment(dt).toISOString(true),
            published: true,
        };
        if (this.createEvent.image !== '') {
            event.image = this.createEvent.image;
        }
        try {
            const res = await this.$apollo.mutate({
                mutation: createEvent,
                variables: {
                    data: event,
                },
                context: {
                    hasUpload: true,
                },
            });
            if (res && res.data) {
                await this.loadEvents();
            }
        } catch (e) {
            console.log('Res error: ', JSON.stringify(e));
        }
    }

    private upload(data: Event): void {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files[0]) {
            console.log('SETTING');
            this.createEvent.image = target.files[0];
        } else {
            console.log('CLEARING');
            this.createEvent.image = '';
        }
        console.log(this.createEvent);
    }

    async mounted(): Promise<void> {
        await this.loadEvents();
    }
}
