


















































































import { Component, Vue } from 'vue-property-decorator';
import { DocumentNode } from 'graphql';
import GetInstagram from '@/graphql/GetInstagram.gql';
import Slick from 'vue-slick';

import getInstagramMediaByToken from '@/requests/getInstagramMediaByToken';
import { InstagramMedia } from '@/types/front-data-type/instagram/instagramMedia';
import { getToken } from '@/requests/tokenApi';

@Component({
    components: {
        Slick,
    },
})
export default class Instagram extends Vue {
    private instagramSlickOptions = {
        infinite: true,
        slidesToShow: 2,
        slidesToScroll: 2,
        // autoplay: true,
        autoplaySpeed: 4000,
        lazyLoad: 'progressive',
        focusOnSelect: true,
        pauseOnHover: true,
        variableWidth: true,
        arrow: false,
        responsive: [
            {
                breakpoint: 993,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2,
                    variableWidth: false,
                },
            },
            {
                breakpoint: 681,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                    variableWidth: false,
                },
            },
        ],
    };
    private instagramMedia: InstagramMedia[] = [];

    $refs!: {
        instSlider: Slick;
    };

    get instagramQuery(): DocumentNode {
        return GetInstagram;
    }

    instagramNext(): void {
        this.$refs.instSlider.next();
    }

    instagramPrev(): void {
        this.$refs.instSlider.prev();
    }

    async mounted(): Promise<void> {
        const fullToken = await getToken();
        if (fullToken) {
            try {
                const mediaRequest = await getInstagramMediaByToken(fullToken);

                if (mediaRequest) {
                    this.instagramMedia = mediaRequest.data.filter(
                        (media: InstagramMedia) => media.media_type === 'IMAGE'
                    );
                    console.log('Instagram Media', this.instagramMedia);
                }
            } catch (e) {
                console.error(e);
            }
        }
    }
}
