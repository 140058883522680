











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { defaultDoc, DocumentType } from '@/types/document';
import moment from 'moment';

import ArButtonDocumentChoose from '@/components/form/ar-button-document-choose.vue';
import { Action } from 'vuex-class';

@Component({
    components: {
        ArButtonDocumentChoose,
    },
})
export default class BooksSlider extends Vue {
    @Prop({ default: defaultDoc }) readonly bookData: DocumentType;

    private isChosen = true;

    @Action('user/CLICK_ON_DOCUMENT_CHOSE_BTN')
    CLICK_ON_DOCUMENT_CHOSE_BTN: (payload: { docId: string, isChosen: boolean }) => boolean;

    get labEndpoint(): string {
        return process.env.VUE_APP_LAB_ENDPOINT
            ? process.env.VUE_APP_LAB_ENDPOINT
            : '';
    }

    public bookUrl(book: DocumentType): string {
        return book.document && book.document.publicUrl
            ? process.env.VUE_APP_API_ENDPOINT + book.document?.publicUrl
            : book.document_url;
    }

    public getBookImgUrl(book: DocumentType): string {
        return book && book.image && book.image.publicUrl
            ? process.env.VUE_APP_API_ENDPOINT + book.image.publicUrl
            : 'img/slider-img/book.jpg';
    }

    public formattedDate(date: string): string {
        return moment(date).format('DD MMMM YYYY');
    }

    async storeToggleChose(id: string): Promise<void> {
        this.isChosen = await this.CLICK_ON_DOCUMENT_CHOSE_BTN({ docId: id, isChosen: this.isChosen });
    }
}
