





import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import $ from 'jquery'
import 'jquery-datetimepicker'
import moment from "moment";
import {v4 as uuid} from 'uuid'


@Component({name: "DateTimePicker"})
export default class DateTimePicker extends Vue {
    @Prop({default: () => moment().format("YYYY-MM-DD")})
    readonly value: string;
    private id = '';
    private jq = $ as any;

    @Emit('input')
    private dateSelected(dt: Date) {
        return moment(dt).format("YYYY-MM-DD");
    }

    mounted(): void {
        this.id = uuid();
        const self = this;
        setTimeout(() => {
            const input = this.jq(`#${this.id}`);
            console.log("calendar created!", input.attr('id'))

            this.jq.datetimepicker.setLocale('uk');

            input.datetimepicker({
                lang: 'uk',
                timepicker: false,
                inline: true,
                format: 'Y-m-d',
                value: self.value,
                scrollMonth: false,
                onSelectDate: function (ct: Date) {
                    self.dateSelected(ct);
                }
            })
        }, 100);
    }
}
