






































































import { Component, Vue, Prop } from 'vue-property-decorator';
import { endpoint } from '@/helpers/endpoint';
import { defaultTag, PostType, TagType } from '@/types/post';
import clone from '@/helpers/clone';
import moment from 'moment';

@Component({ name: 'ArTagPosts' })
export default class ArTagPosts extends Vue {
    private endpoint = endpoint;
    private showItems = 3;
    private addItems = 3;
    @Prop({ default: () => clone<TagType>(defaultTag) })
    readonly tag: TagType;

    get pagedPosts(): PostType[] {
        return this.tag.posts.filter((val: PostType, index: number) => {
            return index <= this.showItems;
        });
    }

    formattedDate(date: string): string {
        return moment(date).format('DD MMMM YYYY');
    }
}
