





import {Vue, Component, Prop, Emit} from "vue-property-decorator";
import $ from 'jquery'
import 'jquery-datetimepicker'
import moment from "moment";
import {v4 as uuid} from 'uuid'


@Component({name: "TimePicker"})
export default class TimePicker extends Vue {
    @Prop({default: () => moment().format("HH:mm")})
    readonly value: string;
    private id = '';
    private jq = $ as any;

    @Emit('input')
    private timeSelected(dt: Date) {
        return moment(dt).format("HH:mm");
    }

    mounted(): void {
        this.id = uuid();
        const self = this;
        setTimeout(() => {
            const input = this.jq(`#${this.id}`);
            console.log("calendar created!", input.attr('id'))

            this.jq.datetimepicker.setLocale('uk');

            input.datetimepicker({
                datepicker: false,
                format: 'H:i',
                value: self.value,
                onSelectTime: function (ct: Date) {
                    self.timeSelected(ct);
                }
            })
        }, 100);
    }
}
