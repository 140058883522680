



















































import { Component, Vue, Prop, Emit, Watch } from 'vue-property-decorator';
import { endpoint } from '@/helpers/endpoint';
import {
    defaultPost,
    LikeRequestType,
    PostType,
    WatchRequestType,
} from '@/types/post';
import clone from '@/helpers/clone';
import graphql from '@/plugins/graphql';
import LikeByUser from '@/graphql/Posts/likedByUser.graphql';
import RemoveLike from '@/graphql/Posts/removeLike.graphql';
import ArLikeIcon from '@/components/layouts/icons/ArLikeIcon.vue';
import ArCommentIcon from '@/components/layouts/icons/ArCommentIcon.vue';
import ArViewsIcon from '@/components/layouts/icons/ArViewsIcon.vue';
import WatchedByUser from '@/graphql/Posts/wached.graphql';
import { Getter } from 'vuex-class';
import { UserType } from '@/types/user';

import UserListTooltip from '@/components/block/user-list-tooltip.vue';


@Component({
    name: 'ArPostHeader',
    components: { ArViewsIcon, ArCommentIcon, ArLikeIcon, UserListTooltip },
})
export default class ArPostHeader extends Vue {
    @Prop({ default: () => clone<PostType>(defaultPost) })
    readonly post: PostType;

    private endpoint = endpoint;

    @Getter('user/getUser')
    private user: UserType | null;

    @Emit('updatePost')
    emitUpdatePost(): boolean {
        return true;
    }

    @Watch('user', { deep: true })
    userWatcher(newVal: UserType | null): void {
        if (newVal !== null && this.post.id) {
            this.viewPost();
        }
    }

    @Watch('post', { deep: true })
    postWatcher(newVal: UserType | null): void {
        if (newVal !== null && newVal.id && this.user) {
            this.viewPost();
        }
    }

    get isLiked(): boolean {
        return (
            this.post.likes.filter((like) => {
                return this.user && like.id === this.user.id;
            }).length > 0
        );
    }

    get isWatched(): boolean {
        return (
            this.post.views.filter((view) => {
                return this.user && view.id === this.user.id;
            }).length > 0
        );
    }

    get commentsLength(): number {
        let count = 0;
        this.post.comments.forEach((comment) => {
            count += 1;
            if (comment.childComments) {
                count += comment.childComments.length;
            }
        });
        return count;
    }

    async likePost(): Promise<void> {
        if (!this.isLiked && this.user) {
            await graphql<LikeRequestType>(LikeByUser, {
                id: this.post.id,
                userId: this.user.id,
            });
        } else if (this.user) {
            await graphql<LikeRequestType>(RemoveLike, {
                id: this.post.id,
                userId: this.user.id,
            });
        }
        this.emitUpdatePost();
    }

    async viewPost(): Promise<void> {
        console.log('CHECKING VIEWED: ', this.isWatched);
        if (!this.isWatched && this.user) {
            const resp = await graphql<WatchRequestType>(WatchedByUser, {
                id: this.post.id,
                userId: this.user.id,
            });
            console.log('RESP: ', resp);
            this.emitUpdatePost();
        }
    }
}
