





































































































































































































import { Component, Vue } from 'vue-property-decorator';
import { DocumentNode } from 'graphql';
import { Action, Getter } from 'vuex-class';
import GetTags from '@/graphql/Tags/GetTags.gql';
import { UserType } from '@/types/user';
import { endpoint } from '@/helpers/endpoint';

@Component
export default class ArNavbar extends Vue {
    private menuOpen = false;

    @Getter('appState/getNavbarStatus')
    private navStatus: boolean;
    @Getter('user/getUser')
    private user: UserType;
    @Action('user/loadUser')
    private loadUser: () => void;

    toggleMenu(): void {
        console.log(1, this.menuOpen);
        this.menuOpen = !this.menuOpen;
        console.log(2, this.menuOpen);
    }

    $refs!: {
        closeBtn: HTMLButtonElement;
    };

    get tagsQuery(): DocumentNode {
        return GetTags;
    }

    get avatar(): string {
        if (this.user && this.user.avatar) {
            return `${endpoint}${this.user.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    private signOut() {
        console.log('Singing out');

        localStorage.removeItem('authToken');
        localStorage.removeItem('expireTime');
        localStorage.removeItem('tempKey');

        this.$router.push('/login');
    }

    async mounted(): Promise<void> {
        if (!this.user) {
            await this.loadUser();
        }
    }
}
