























































import { Component, Vue } from 'vue-property-decorator';
import EditionsLessThenCurrent from '@/graphql/Editions/lastEditionsWithLimAndExcept.graphql';
import EditionsWithoutLustOne from '@/graphql/Editions/editionsWithoutLastone.graphql';
import AllEditions from '@/graphql/Editions/allEditions.graphql';
import {
    EditionRequestType,
    EditionsRequestTypes,
    EditionType,
} from '@/types/edition';
import graphql from '@/plugins/graphql';
import EditionQuery from '@/graphql/Editions/editionById.graphql';

@Component({})
export default class LastEditions extends Vue {
    private prevEditions: EditionType[] = [];
    private allEditions: EditionType[] = [];

    get id(): string | null {
        if (this.$route.params.editionId) {
            return this.$route.params.editionId;
        }
        return null;
    }

    changeEdition(event: { target: HTMLInputElement }): void {
        if (!isNaN(parseInt(event.target.value))) {
            this.$router.push(`/edition/${event.target.value}`);
        }
    }

    async created(): Promise<void> {
        if (this.id) {
            const editionRequest = await graphql<EditionRequestType>(
                EditionQuery,
                { id: this.id }
            );
            if (editionRequest) {
                const currentEdition = editionRequest.Edition;
                const prevEditionsRequest = await graphql<EditionsRequestTypes>(
                    EditionsLessThenCurrent,
                    { currentNumber: currentEdition.number }
                );
                if (prevEditionsRequest) {
                    this.prevEditions = prevEditionsRequest.allEditions;
                }
            }
        } else {
            const prevEditionsRequest = await graphql<EditionsRequestTypes>(
                EditionsWithoutLustOne,
                { count: 3 }
            );
            if (prevEditionsRequest) {
                this.prevEditions = prevEditionsRequest.allEditions;
            }
        }

        const allEditions = await graphql<EditionsRequestTypes>(AllEditions);
        if (allEditions) {
            this.allEditions = allEditions.allEditions;
        }
    }
}
