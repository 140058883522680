import { defaultFile, FileType } from "@/types/base/file";
import clone from "@/helpers/clone";
import { DocumentType } from '@/types/document';

export type UserType = {
    avatar: FileType
    id?: string
    isAdmin: boolean
    name: string,
    position?: string
    email: string,
    chosenDocuments?: DocumentType[]
}

export type UserCreateRequestType = {
    createUser: UserType
}

export type AuthUserRequestType = {
    authenticate?: {
        item: UserType,
        token: string
    }
}

export type GetUserByEmailRequestType = {
    allUsers: UserType[]
}
export type GetUserByTokenRequestType = {
    allUsers: UserType[]
}

export const defaultUser = {
    avatar: clone(defaultFile),
    id: '',
    isAdmin: false,
    name: '',
    email: ''
}
