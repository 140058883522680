


















































import { Vue, Component, Prop } from 'vue-property-decorator';
import { endpoint } from '@/helpers/endpoint';
import { TagTopPost } from '@/types/tagTopPost';
import clone from '@/helpers/clone';
import { defaultTagLatestPost, TagLatestPost } from '@/types/post';
import graphql from '@/plugins/graphql';
import tagLatestPost from '@/graphql/Tags/tagLatestPost.graphql';
import latestPost from '@/graphql/Posts/latestPost.graphql';

import ArLikeIcon from '@/components/layouts/icons/ArLikeIcon.vue';
import ArCommentIcon from '@/components/layouts/icons/ArCommentIcon.vue';
import ArViewsIcon from '@/components/layouts/icons/ArViewsIcon.vue';

import UserListTooltip from '@/components/block/user-list-tooltip.vue';


@Component({
    name: 'ArTagHeader',
    components: { ArViewsIcon, ArCommentIcon, ArLikeIcon, UserListTooltip },
})
export default class ArTagHeader extends Vue {
    @Prop({ default: 'explore' }) readonly id: string;
    @Prop({ default: 'Explore' }) readonly tagName: string;

    private endpoint = endpoint;
    private latestPost: TagTopPost = clone<TagTopPost>(defaultTagLatestPost);

    async created(): Promise<void> {
        if (this.id !== 'explore') {
            const latestPost = await graphql<TagLatestPost>(tagLatestPost, {
                tagId: this.id,
            });
            if (latestPost && latestPost.allPosts.length === 1) {
                this.latestPost = latestPost.allPosts[0];
            }
        } else {
            const latestPostResp = await graphql<TagLatestPost>(latestPost);
            if (latestPostResp && latestPostResp.allPosts.length === 1) {
                this.latestPost = latestPostResp.allPosts[0];
            }
        }
    }
}
