






















import { Vue, Component, Emit, Prop } from 'vue-property-decorator';

export type ReportData = {
    desc: string;
    text: string;
};

// noinspection JSUnusedGlobalSymbols
@Component
export default class TextErrorReportButton extends Vue {
    @Prop({ default: 'Сообщить об ошибке' }) readonly altText: string;
    @Prop({ default: 'Опишите ошибку' }) readonly promptText: string;

    private reportPosition = { x: '0px', y: '0px' };
    private showButton = false;
    private selectedText = '';

    @Emit('reported')
    emitData(desc: string, text: string): ReportData {
        return { desc, text };
    }

    onTextSelected(event: MouseEvent | TouchEvent): boolean {
        const target = event.target as HTMLElement;
        const skip = ['BUTTON', 'IMG'].indexOf(target.tagName) !== -1;
        if (skip) {
            return false;
        }
        event.stopPropagation();
        event.preventDefault();
        this.selectedText = window.getSelection()?.toString() + '';
        if (event.type === 'touchend') {
            event = event as TouchEvent;
            let touch = event.touches[0] || event.changedTouches[0];
            this.reportPosition.y = touch.pageY + 'px';
            this.reportPosition.x = touch.pageX + 'px';
        } else {
            event = event as MouseEvent;
            this.reportPosition.y = event.pageY + 'px';
            this.reportPosition.x = event.pageX + 'px';
        }
        console.log('Selected: ', this.reportPosition.x, this.reportPosition.y);
        this.showButton = this.selectedText !== '';
        return false;
    }

    showPrompt(): void {
        const msg = prompt(this.promptText);
        if (msg) {
            this.emitData(msg, this.selectedText);
        }
        this.showButton = false;
    }

    mounted(): void {
        document.addEventListener('mouseup', this.onTextSelected);
    }

    beforeUnmount(): void {
        document.removeEventListener('mouseup', this.onTextSelected);
    }
}
