











import {Component, Prop, Vue} from 'vue-property-decorator';
import FullDoc from '@/components/layouts/profile/full-doc.vue';
import { DocumentType } from "@/types/document";

@Component({
    name: 'FullDocs',
    components: {
        FullDoc,
    },
})
export default class FullDocs extends Vue {
    @Prop({default: false}) readonly isRead: boolean;
    @Prop({default: []})
    readonly listData!: DocumentType[];
}
