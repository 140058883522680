










































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import { PostType } from '@/types/post';
import { endpoint } from '@/helpers/endpoint';
import moment from 'moment';

@Component({
    components: {
        Slick,
    },
})
export default class PostsSlider extends Vue {
    @Prop({
        default: () => {
            return [];
        },
    })
    readonly posts: PostType[];
    private endpointUrl = endpoint;

    next(): void {
        this.$refs.slider.next();
    }
    prev(): void {
        this.$refs.slider.prev();
    }

    $refs!: {
        slider: Slick;
    };

    formattedDate(date: string): string {
        return moment(date).format('DD MMMM YYYY');
    }

    private slickOptions = {
        infinite: true,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 4000,
        focusOnSelect: true,
        pauseOnHover: true,
        centerMode: true,
        variableWidth: true,
        arrow: true,
        responsive: [
            {
                breakpoint: 540,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                },
            },
        ],
    };
}
