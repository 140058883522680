import axios from 'axios';
import { InstagramMediaList } from '@/types/front-data-type/instagram/instagramMedia';

export default async function (
    fullTokenId: string
): Promise<InstagramMediaList | false> {
    try {
        const url = 'https://graph.instagram.com/me/media';

        const params = new URLSearchParams();
        params.append('fields', 'id,media_url,permalink,media_type');
        params.append('access_token', fullTokenId);

        const response = await axios.get<InstagramMediaList>(url, {
            params,
            headers: {
                'content-type': 'application/x-www-form-urlencoded',
            },
        });

        return response.data;
    } catch (e) {
        return false;
    }
}
