








import { Component, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import LastEditions from '@/components/layouts/LastEditions.vue';
import Instagram from '@/components/layouts/Instagram.vue';
import graphql from '@/plugins/graphql';
import TagById from '@/graphql/Tags/tagById.graphql';
import allPosts from '@/graphql/Posts/allPosts.graphql';
import clone from '@/helpers/clone';
import {
    AllPostsRequest,
    defaultTag,
    TagRequestType,
    TagType,
} from '@/types/post';
import ArTagHeader from '@/components/layouts/tag/ArTagHeader.vue';
import ArTagPosts from '@/components/layouts/tag/ArTagPosts.vue';

@Component({
    components: {
        ArTagPosts,
        ArTagHeader,
        Instagram,
        LastEditions,
        Slick,
    },
})
export default class Tag extends Vue {
    private id = this.$route.params.tagId;
    private tag: TagType = clone<TagType>(defaultTag);

    async created(): Promise<void> {
        window.scrollTo(0, 0);
        if (this.id !== 'explore') {
            const tagRequest = await graphql<TagRequestType>(TagById, {
                id: this.id,
            });
            if (tagRequest) {
                this.tag = tagRequest.Tag;
            }
        } else {
            this.tag.name = 'Explore';
            const allPostsResp = await graphql<AllPostsRequest>(allPosts);
            if (allPostsResp) {
                this.tag.posts = allPostsResp.allPosts;
            }
        }
    }
}
