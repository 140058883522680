













































































































import { Component, Vue, Prop, Watch } from 'vue-property-decorator';
import { endpoint } from '@/helpers/endpoint';
import {
    CommentsRequestType,
    CommentType,
    CreateCommentType,
    defaultPost,
    PostType,
} from '@/types/post';
import clone from '@/helpers/clone';
import moment from 'moment';
import { Getter } from 'vuex-class';
import { UserType } from '@/types/user';
import graphql from '@/plugins/graphql';
import CreateComment from '@/graphql/Comments/createComment.gql';
import CommentsByPost from '@/graphql/Comments/commentsByPostId.gql';

@Component
export default class ArPostFooter extends Vue {
    @Prop({ default: () => clone<PostType>(defaultPost) })
    readonly post: PostType;

    private comments: CommentType[] = [];
    private showReply = '0';
    private commentText = '';
    private commentReplyText = '';

    @Getter('user/getUser')
    private user: UserType;

    @Watch('post', { deep: true })
    postWatcher(newVal: PostType | null): void {
        if (newVal && newVal.id) {
            this.updateComments();
        }
    }

    get avatar(): string {
        if (this.user && this.user.avatar) {
            return `${endpoint}${this.user.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    get postAuthorAvatar(): string {
        if (
            this.post &&
            this.post.author &&
            this.post.author.avatar &&
            this.post.author.avatar.publicUrl
        ) {
            return `${endpoint}${this.post.author.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    timeFromNow(date: string): string {
        return moment(date).fromNow();
    }

    commentAvatar(comment: CommentType): string {
        if (
            comment.author &&
            comment.author.avatar &&
            comment.author.avatar.publicUrl
        ) {
            return `${endpoint}${comment.author.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    showReplyInput(comment: CommentType): void {
        if (comment.id) {
            this.showReply = comment.id;
        }
    }

    async updateComments(): Promise<void> {
        const postCommentsRequest = await graphql<CommentsRequestType>(
            CommentsByPost,
            { id: this.post.id }
        );
        if (postCommentsRequest) {
            this.comments = postCommentsRequest.allComments;
        }
    }

    async createComment(commentId: string | null = null): Promise<void> {
        const comment: Record<string, unknown> = {
            text: this.commentText,
            post: { connect: { id: this.post.id } },
            author: { connect: { id: this.user.id } },
        };
        if (commentId) {
            comment.text = this.commentReplyText;
            comment.parent = { connect: { id: commentId } };
        }
        const createCommentRequest = await graphql<CreateCommentType>(
            CreateComment,
            { data: comment }
        );
        if (createCommentRequest) {
            await this.updateComments();
        }
        this.commentText = '';
        this.commentReplyText = '';
        this.showReply = '0';
    }
}
