import Vue from 'vue';
import VueRouter, { RouteConfig } from 'vue-router';
import Edition from '@/views/Edition.vue';
import Tag from '@/views/Tag.vue';
import Post from '@/views/Post.vue';
import GetTogether from '@/views/GetTogether.vue';
import Login from '@/views/Login.vue';
import moment from 'moment';
import Profile from '@/views/Profile.vue';
import GalleryLogin from '@/views/GalleryLogin.vue';
import Gallery from '@/views/Gallery.vue';
import Import from '@/views/Import.vue';
import InstagramAuthLogin from '@/views/InstagramAuthLogin.vue';
import InstagramAuth from '@/views/InstagramAuth.vue';

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
    {
        path: '/',
        name: 'Home',
        component: Edition,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/edition',
        name: 'Edition',
        component: Edition,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/edition/:editionId',
        name: 'NotLastEdition',
        component: Edition,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/tag/:tagId',
        name: 'Tag',
        component: Tag,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/posts/:postId',
        name: 'Post',
        component: Post,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/together',
        name: 'Together',
        component: GetTogether,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/gallery/login',
        name: 'GalleryLogin',
        component: GalleryLogin,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/gallery',
        name: 'Gallery',
        component: Gallery,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/instagram-auth/login',
        name: 'InstagramAuthLogin',
        component: InstagramAuthLogin,
        meta: {
            requireAuth: false,
        },
    },
    {
        path: '/instagram-auth',
        name: 'InstagramAuth',
        component: InstagramAuth,
        meta: {
            requireAuth: true,
        },
    },
    {
        path: '/import',
        name: 'Import',
        component: Import,
        meta: {
            requireAuth: false,
        },
    },
];

const router = new VueRouter({
    mode: 'history',
    routes,
});

router.beforeEach(async (from, to, next) => {
    const shouldRun = from.meta.requireAuth;
    // const shouldRun = false;
    if (shouldRun) {
        if (to.path === '/gallery' || from.path === '/gallery') {
            const galleryToken = localStorage.getItem('galleryAuthToken');
            if (!galleryToken) {
                console.log('toLogin', galleryToken);
                next({
                    name: 'GalleryLogin',
                });
            } else {
                next();
            }
        } else {
            // next();
            const token = localStorage.getItem('authToken');
            const expireTime = localStorage.getItem('expireTime');
            if (!token || !expireTime || +expireTime < moment().unix()) {
                localStorage.clear();
                next('/login');
            } else {
                next();
            }
        }
    } else {
        next();
    }
});

export default router;
