




























































import { Component, Vue } from "vue-property-decorator";
import { Mutation } from "vuex-class";
import slideshow from "vue-gallery-slideshow";
import { ImportDocType, RelationObjectType, TimeFixObjectType } from "@/types/import";
import whereDocumentType from "@/graphql/Lab/Filters/WhereDocumentTypes.gql";
import whereMaterial from "@/graphql/Lab/Filters/WhereMaterials.gql";
import whereProject from "@/graphql/Lab/Filters/WhereProjects.gql";
import whereLanguage from "@/graphql/Lab/Filters/WhereLanguages.gql";
import whereUser from "@/graphql/Lab/Filters/WhereUsers.gql";
import whereProceeding from "@/graphql/Lab/Filters/WhereProceedings.gql";
import whereIndustry from "@/graphql/Lab/Filters/WhereIndustries.gql";
import whereTag from "@/graphql/Lab/Filters/WhereDocumentTags.gql";
import getDocByImportId from "@/graphql/Lab/GetDocByImportId.gql";

import createDocumentType from "@/graphql/Lab/Filters/CreateDocumentType.gql";
import createLanguage from "@/graphql/Lab/Filters/CreateLanguage.gql";
import createMaterial from "@/graphql/Lab/Filters/CreateMaterial.gql";
import createProject from "@/graphql/Lab/Filters/CreateProject.gql";
import createProceeding from "@/graphql/Lab/Filters/CreateProceeding.gql";
import createIndustry from "@/graphql/Lab/Filters/CreateIndustry.gql";
import createDocument from "@/graphql/Lab/CreateDocument.gql";
import updateDocument from "@/graphql/Lab/UpdateDocument.gql";
import getDocsByFilters from "@/graphql/Lab/GetDocsByFilters.gql";
import createTag from "@/graphql/Lab/Filters/CreateDocumentTag.gql";
import createUser from "@/graphql/Users/createUser.gql";
import { GetDocumentsRequest } from "@/types/document";
import graphql from "@/plugins/graphql";
import {
    CreateDocumentTagRequest,
    CreateDocumentTypeRequest, CreateIndustryRequest,
    CreateLanguageRequest,
    CreateMaterialRequest,
    CreateProceedingRequest,
    CreateProjectRequest, CreateUserRequest, CreateUserType, WhereDocumentTagsRequest,
    WhereDocumentTypesRequest, WhereIndustriesRequest,
    WhereLanguagesRequest,
    WhereMaterialsRequest,
    WhereProceedingsRequest,
    WhereProjectsRequest, WhereUsersRequest
} from "@/types/filters";
import axios from "axios";
import moment from "moment";

@Component({
    components: { slideshow }
})
export default class Gallery extends Vue {
    @Mutation("appState/SET_NAVBAR_STATUS")
    private setNavStatus: (status: boolean) => void;

    private showUploadInput = true;
    private showRelationsUploadInput = true
    private showTimeFixInput = true
    private showDialog = false;
    private showRelationsDialog = false;
    private showTimeFixDialog = false;
    private jsonFile: File;
    private relationsJson: File;

    async created(): Promise<void> {
        this.setNavStatus(false);
    }

    async uploadTimeFixFile(data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
            this.relationsJson = target.files[0];
            this.showTimeFixDialog = true;
            this.showTimeFixInput = false;
        }
    }

    async uploadRelationsFile(data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
            this.relationsJson = target.files[0];
            this.showRelationsDialog = true;
            this.showRelationsUploadInput = false;
        }
    }

    async uploadFile(data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
            this.jsonFile = target.files[0];
            this.showDialog = true;
            this.showUploadInput = false;
        }
    }

    async startImport(): Promise<void> {
        const reader = new FileReader(); // File reader to read the file
        reader.readAsText(this.jsonFile); // Read the uploaded file

        // This event listener will happen when the reader has read the file
        reader.addEventListener("load", async () => {
            if (typeof reader.result === "string") {
                const result = JSON.parse(reader.result); // Parse the result into an object
                console.log({ result: result });
                if (result) {
                    await this.importProcess(result);
                }
            }
        });

    }

    async startRelationsImport(): Promise<void> {
        const reader = new FileReader(); // File reader to read the file
        reader.readAsText(this.relationsJson); // Read the uploaded file

        // This event listener will happen when the reader has read the file
        reader.addEventListener("load", async () => {
            if (typeof reader.result === "string") {
                const result = JSON.parse(reader.result); // Parse the result into an object
                console.log({ result: result });
                if (result) {
                    await this.importRelationsProcess(result);
                }
            }
        });

    }

    async startTimeFixImport(): Promise<void> {
        const reader = new FileReader(); // File reader to read the file
        reader.readAsText(this.relationsJson); // Read the uploaded file

        // This event listener will happen when the reader has read the file
        reader.addEventListener("load", async () => {
            if (typeof reader.result === "string") {
                const result = JSON.parse(reader.result); // Parse the result into an object
                console.log({ result: result });
                if (result) {
                    await this.importTimeFixProcess(result);
                }
            }
        });

    }

    async importProcess(importJson: ImportDocType[]): Promise<void> {
        try {
            let i = 1;
            for (const item of importJson) {
                await this.importDoc(item);
                i++
                console.log({i})
            }
        } catch (e) {
            console.log({ e });
        }
    }

    async importRelationsProcess(importJson: ImportDocType[]): Promise<void> {
        try {
            let i = 1;
            for (const item of importJson) {
                await this.importRelations(item);
                i++
                console.log({i})
            }
        } catch (e) {
            console.log({ e });
        }
    }

    async importTimeFixProcess(importJson: ImportDocType[]): Promise<void> {
        try {
            let i = 1;
            for (const item of importJson) {
                await this.importTimeFix(item);
                i++
                console.log({i})
            }
        } catch (e) {
            console.log({ e });
        }
    }

    async importDoc(doc: ImportDocType): Promise<void> {
        const preparedForImport = await this.checkAndPrepareImportItem(doc);
        console.log({preparedForImport})
        if (preparedForImport) {
            const res = await this.$apollo.mutate({
                mutation: createDocument,
                variables: { data: preparedForImport },
                context: {
                    hasUpload: true
                }
            });
            return res && res.data;
        }
    }

    async importRelations(doc: ImportDocType): Promise<void> {
        const preparedForImport = await this.checkAndPrepareImportRelationsItem(doc);
        console.log({preparedForImport})
        if (preparedForImport.docId && preparedForImport.related.length > 0) {
            const res = await this.$apollo.mutate({
                mutation: updateDocument,
                variables: {
                    id: preparedForImport.docId,
                    data:
                        {
                            related: {connect: preparedForImport.related}
                        }
                    },
                context: {
                    hasUpload: true
                }
            });
            return res && res.data;
        }
    }

    async importTimeFix(doc: ImportDocType): Promise<void> {
        const preparedForImport = await this.checkAndPrepareTimeFixItem(doc);
        console.log({preparedForImport})
        if (preparedForImport.docId
            && preparedForImport.createdAt.length > 0
            && preparedForImport.updatedAt.length > 0
        ) {
            const res = await this.$apollo.mutate({
                mutation: updateDocument,
                variables: {
                    id: preparedForImport.docId,
                    data:
                        {
                            static_created_date: preparedForImport.createdAt,
                            static_updated_date: preparedForImport.updatedAt
                        }
                },
                context: {
                    hasUpload: true
                }
            });
            return res && res.data;
        }
    }

    async checkAndPrepareImportItem(item: ImportDocType): Promise<Record<string, unknown> | null> {
        const duplicate = await this.checkForDuplicate(item.importId);
        // const duplicate = false;
        const fileExist = await this.checkFileExist(`https://labdocs.aequo.ua/documents/${item.document[0]}`)
        console.log({duplicate, fileExist});
        if (fileExist && !duplicate) {
            let newDocResult: Record<string, unknown> = {};
            newDocResult.importId = item.importId ? item.importId : undefined;
            newDocResult.author = item.bookAuthor ? item.bookAuthor : undefined;
            newDocResult.isBook = item.isBook ? item.isBook : undefined
            newDocResult.static_created_date = item.created_at
                ? moment(item.created_at).format('YYYY-MM-DD')
                : undefined;
            newDocResult.title = item.title
                ? item.title
                : item.document[0].split('/').pop();
            newDocResult.internalDocumentation = item.internalDocumentation
                ? item.internalDocumentation
                : undefined;
            newDocResult.description = item.description
                ? item.description
                : item.document[0].split('/').pop();
            newDocResult.full_description = item.full_description
                ? item.full_description
                : undefined
            newDocResult.language = item.language
                ? await this.getValidLanguage(item.language)
                : undefined;
            newDocResult.project = item.project
                ? await this.getValidProject(item.project)
                : undefined;
            newDocResult.material = item.material
                ? await this.getValidMaterial(item.material)
                : undefined;
            newDocResult.type = item.type
                ? await this.getValidType(item.type)
                : undefined;
            newDocResult.proceedings = item.proceedings
                ? await this.getValidProceedings(item.proceedings)
                : undefined;
            newDocResult.industries = item.industries
                ? await this.getValidIndustries(item.industries)
                : undefined;
            newDocResult.tags = item.tags
                ? await this.getValidTags(item.tags)
                : undefined;
            newDocResult.subAuthors = item.subAuthors
                ? await this.getValidUsersByEmail(item.subAuthors)
                : undefined;
            newDocResult.owner = item.owner
                ? await this.getValidUser(item.owner)
                : undefined

            const blobDocument = await this.getFile(`https://labdocs.aequo.ua/documents/${item.document[0]}`)
            if (item.image && item.image.length > 3) {
                const imageFile = await this.getFile(`${item.image}`)
                if (imageFile) {
                    newDocResult.image = imageFile
                }
            }

            if (blobDocument) {
                newDocResult.document = blobDocument
                return newDocResult;
            }
            return null;
        }
        return null;
    }

    async checkAndPrepareImportRelationsItem(item: ImportDocType): Promise<RelationObjectType> {
        const respObject: RelationObjectType = {
            docId: '',
            related: []
        }
        const doc = await graphql<GetDocumentsRequest>(getDocByImportId,
            { importId: item.importId }
        );
        console.log({doc})
        if (doc && doc.allDocuments && doc.allDocuments.length > 0) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            respObject.docId = doc.allDocuments[0].id!
            const relatedDocs = await graphql<GetDocumentsRequest>(
                getDocsByFilters,
                {where: {importId_in: item.related}}
            )
            console.log({relatedDocs});
            if (relatedDocs && relatedDocs.allDocuments && relatedDocs.allDocuments.length > 0) {
                respObject.related = relatedDocs.allDocuments.map((doc) => {
                    return {id: doc.id}
                })
            }
        }
        return respObject;
    }

    async checkAndPrepareTimeFixItem(item: ImportDocType): Promise<TimeFixObjectType> {
        const respObject: TimeFixObjectType = {
            docId: '',
            createdAt: '',
            updatedAt: ''
        }
        const doc = await graphql<GetDocumentsRequest>(getDocByImportId,
            { importId: item.importId }
        );
        console.log({doc})
        if (doc && doc.allDocuments && doc.allDocuments.length > 0 && doc.allDocuments[0].id) {
            respObject.docId = doc.allDocuments[0].id
            respObject.createdAt = moment(item.created_at).format('YYYY-MM-DD')
            respObject.updatedAt = moment(item.updated_at).format('YYYY-MM-DD')
        }
        return respObject;
    }

    async getFile(url: string): Promise<File | null> {
        try {
            const file = await axios({
                url,
                method: "GET",
                responseType: "blob",
                headers: {
                    'Authorization': "Basic YWx0cmVjaXBlOkt5c3JHMzRyTVA="
                }
            }
            );
            if (file && file.data) {
                return this.blobToFile(file.data, url);
            }
        } catch (e) {
            return null
        }
        return null
    }

    async checkForDuplicate(importId: string): Promise<boolean> {
        const checkIfExist = await graphql<GetDocumentsRequest>(getDocByImportId,
            { importId }
        );
        console.log({checkIfExist})
        return !!(checkIfExist &&
            checkIfExist.allDocuments &&
            checkIfExist.allDocuments.length > 0);
    }

    blobToFile(blobFile: Blob, url: string): File {
        const fileName = url.substring(url.lastIndexOf("/") + 1);
        return new File([blobFile], fileName);
    }

    async getValidTags
        (tags: string[]): Promise<Record<string, unknown> | null>
    {
        if (tags.length < 1) {
            return null;
        }
        const tagsConnections: Record<string, unknown>[] = [];

        for (const tag of tags) {
            const checkIfExist = await graphql<WhereDocumentTagsRequest>(
                whereTag, { where: { name: tag } }
            );
            console.log({ checkIfExist, tag});
            // if language already exist in system
            if (
                checkIfExist &&
                checkIfExist.allDocumentTags &&
                checkIfExist.allDocumentTags.length > 0
            ) {
                tagsConnections.push({ id: checkIfExist.allDocumentTags[0].id });
            } else {
                const newTag = await graphql<CreateDocumentTagRequest>
                (
                    createTag, { data: { name: tag } }
                );

                console.log({ newTag, tag });
                if (newTag && newTag.createDocumentTag) {
                    tagsConnections.push({ id: newTag.createDocumentTag.id });
                }
            }
        }

        if (tagsConnections.length > 0 ) {
            return { connect: tagsConnections }
        }
        return null
    }

    async getValidIndustries
        (industries: string[]): Promise<Record<string, unknown> | null>
    {
        if (industries.length < 1) {
            return null;
        }
        const industriesConnections: Record<string, unknown>[] = [];

        for (const industry of industries) {
            const checkIfExist = await graphql<WhereIndustriesRequest>(
                whereIndustry, { where: { name: industry } }
            );
            console.log({ checkIfExist, industry});
            // if language already exist in system
            if (
                checkIfExist &&
                checkIfExist.allIndustries &&
                checkIfExist.allIndustries.length > 0
            ) {
                industriesConnections.push({ id: checkIfExist.allIndustries[0].id });
            } else {
                const newIndustry = await graphql<CreateIndustryRequest>
                (
                    createIndustry, { data: { name: industry } }
                );

                console.log({ newIndustry, industry });
                if (newIndustry && newIndustry.createIndustry) {
                    industriesConnections.push({ id: newIndustry.createIndustry.id });
                }
            }
        }

        if (industriesConnections.length > 0 ) {
            return { connect: industriesConnections }
        }
        return null
    }

    async getValidProceedings
        (proceedings: string[]): Promise<Record<string, unknown> | null>
    {
        if (proceedings.length < 1) {
            return null;
        }
        const proceedingsConnections: Record<string, unknown>[] = [];

        for (const proceeding of proceedings) {
            const checkIfExist = await graphql<WhereProceedingsRequest>(
                whereProceeding, { where: { name: proceeding } }
            );
            console.log({ checkIfExist, proceeding});
            // if language already exist in system
            if (
                checkIfExist &&
                checkIfExist.allProceedings &&
                checkIfExist.allProceedings.length > 0
            ) {
                proceedingsConnections.push({ id: checkIfExist.allProceedings[0].id });
            } else {
                const newProceeding = await graphql<CreateProceedingRequest>
                (
                    createProceeding, { data: { name: proceeding } }
                );

                console.log({ newProceeding, proceeding });
                if (newProceeding && newProceeding.createProceeding) {
                    proceedingsConnections.push({ id: newProceeding.createProceeding.id });
                }
            }
        }

        if (proceedingsConnections.length > 0 ) {
            return { connect: proceedingsConnections }
        }
        return null
    }

    async getValidLanguage(language: string): Promise<Record<string, unknown> | null> {
        const checkIfExist = await graphql<WhereLanguagesRequest>(whereLanguage,
            { where: { name: language } }
        );
        // if language already exist in system
        if (
            checkIfExist &&
            checkIfExist.allLanguages &&
            checkIfExist.allLanguages.length > 0
        ) {
            return { connect: { id: checkIfExist.allLanguages[0].id } };
        }

        const newLanguage = await graphql<CreateLanguageRequest>(createLanguage,

            { data: { name: language } }
        );
        if (newLanguage && newLanguage.createLanguage) {
            return { connect: { id: newLanguage.createLanguage.id } };
        }
        return null;
    }

    async getValidUsersByEmail(users: CreateUserType[]): Promise<Record<string, unknown> | null> {
        if (users.length < 1) {
            return null;
        }
        const usersConnections: Record<string, unknown>[] = [];

        for (const user of users) {
            const checkIfExist = await graphql<WhereUsersRequest>(
                whereUser, { where: { email: user.email } }
            );
            console.log({ checkIfExist, user});
            // if user already exist in system
            if (
                checkIfExist &&
                checkIfExist.allUsers &&
                checkIfExist.allUsers.length > 0
            ) {
                usersConnections.push({ id: checkIfExist.allUsers[0].id });
            } else {
                const newUser = await graphql<CreateUserRequest>
                (
                    createUser, { data: user }
                );

                console.log({ newUser, user });
                if (newUser && newUser.createUser) {
                    usersConnections.push({ id: newUser.createUser.id });
                }
            }
        }

        if (usersConnections.length > 0 ) {
            return { connect: usersConnections }
        }
        return null
    }

    async getValidUser(user: CreateUserType): Promise<Record<string, unknown> | null> {
        const checkIfExist = await graphql<WhereUsersRequest>(whereUser,
            { where: { email: user.email } }
        );
        // if user already exist in system
        if (
            checkIfExist &&
            checkIfExist.allUsers &&
            checkIfExist.allUsers.length > 0
        ) {
            return { connect: { id: checkIfExist.allUsers[0].id } };
        }

        const newUser = await graphql<CreateUserRequest>(createUser,

            { data: user }
        );
        if (newUser && newUser.createUser) {
            return { connect: { id: newUser.createUser.id } };
        }
        return null;
    }

    async getValidProject(project: string): Promise<Record<string, unknown> | null> {
        const checkIfExist = await graphql<WhereProjectsRequest>(whereProject,
            { where: { name: project } }
        );
        // if project already exist in system
        if (
            checkIfExist &&
            checkIfExist.allProjects &&
            checkIfExist.allProjects.length > 0
        ) {
            return { connect: { id: checkIfExist.allProjects[0].id } };
        }

        const newProject = await graphql<CreateProjectRequest>(createProject,

            { data: { name: project } }
        );
        if (newProject && newProject.createProject) {
            return { connect: { id: newProject.createProject.id } };
        }
        return null;
    }

    async getValidMaterial(material: string): Promise<Record<string, unknown> | null> {
        const checkIfExist = await graphql<WhereMaterialsRequest>(whereMaterial,
            { where: { name: material } }
        );
        // if material already exist in system
        if (
            checkIfExist &&
            checkIfExist.allMaterials &&
            checkIfExist.allMaterials.length > 0
        ) {
            return { connect: { id: checkIfExist.allMaterials[0].id } };
        }

        const newMaterial = await graphql<CreateMaterialRequest>(createMaterial,

            { data: { name: material } }
        );
        if (newMaterial && newMaterial.createMaterial) {
            return { connect: { id: newMaterial.createMaterial.id } };
        }
        return null;
    }

    async getValidType(type: string): Promise<Record<string, unknown> | null> {
        const checkIfExist = await graphql<WhereDocumentTypesRequest>(whereDocumentType,
            { where: { name: type } }
        );
        if (
            checkIfExist &&
            checkIfExist.allDocumentTypes &&
            checkIfExist.allDocumentTypes.length > 0
        ) {
            return { connect: { id: checkIfExist.allDocumentTypes[0].id } };
        }

        const newType = await graphql<CreateDocumentTypeRequest>(createDocumentType,

            { data: { name: type, plural:  type} }
        );
        console.log({newType})
        if (newType && newType.createDocumentType) {
            return { connect: { id: newType.createDocumentType.id } };
        }
        return null;
    }

    async checkFileExist(urlToFile: string): Promise<boolean> {
        console.log('checkFileExistMethod',urlToFile)
        try {
            let headers = new Headers();
            headers.set('Authorization', 'Basic YWx0cmVjaXBlOkt5c3JHMzRyTVA==');

            const response = await fetch(urlToFile, {
                method: 'HEAD',
                cache: 'no-cache',
                headers
            });
            console.log({fetchResp:response});
            return response.status === 200;

        } catch(error) {
            console.log('fetchError', error);
            return false;
        }

    }

    dropDataToDefault(): void {
        this.showUploadInput = true;
        this.showDialog = false;
    }
}
