<template>
    <svg
        width="24"
        height="24"
        viewbox="-3 0 26 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M15.95 0C14.025 0 12.2101 0.915578 11 2.40335C9.78984 0.915578 7.975 0 6.05 0C2.63989 0 0 2.74646 0 6.29426C0 10.6428 3.73989 14.1336 9.40479 19.5119L11 21L12.5952 19.5119C18.2601 14.1335 22 10.6427 22 6.29426C22 2.74646 19.3601 0 15.95 0Z"
            fill="#4383FF"
        />
    </svg>
</template>

<script>
export default {
    name: 'ar-like-icon',
};
</script>

<style scoped></style>
