import { PostType } from '@/types/post';
import { defaultFile, FileType } from '@/types/base/file';

export type photo = {
    originalName: string;
    url: string;
};

export type EditionType = {
    weeklyPhotos: photo[];
    mainBanner: FileType;
    name: string;
    number: string;
    id: string;
    posts: PostType[];
};

export type EditionRequestType = {
    Edition: EditionType;
};

export type EditionsRequestTypes = {
    allEditions: EditionType[];
};

export const defaultEdition: EditionType = {
    weeklyPhotos: [],
    mainBanner: defaultFile,
    posts: [],
    name: '',
    number: '',
    id: '',
};
