











































































import { Component, Prop, Vue } from 'vue-property-decorator';
import { Action } from 'vuex-class';

import ArButtonDocumentChoose from '@/components/form/ar-button-document-choose.vue';
import { getDate } from '@/helpers/date';
import { defaultDoc, DocumentType } from '@/types/document';

@Component({
    name: 'FullDoc',
    components: {
        ArButtonDocumentChoose,
    },
})
export default class FullDoc extends Vue {
    @Prop({ default: defaultDoc }) readonly docData: DocumentType;
    @Prop({ default: false }) readonly isRead: boolean;

    private isChosen = true;

    @Action('user/CLICK_ON_DOCUMENT_CHOSE_BTN')
    CLICK_ON_DOCUMENT_CHOSE_BTN: (payload: { docId: string, isChosen: boolean }) => boolean;

    private get labEndpoint(): string {
        return process.env.VUE_APP_LAB_ENDPOINT
            ? process.env.VUE_APP_LAB_ENDPOINT
            : 'https://labtest.aequo.ua';
    }

    private get docUrl(): string {
        console.log('docdata', this.docData);
        return this.docData.document && this.docData.document.publicUrl
            ? process.env.VUE_APP_API_ENDPOINT +
                  this.docData.document?.publicUrl
            : this.docData.document_url;
    }

    private get createdAt(): string {
        return this.docData.static_created_date
            ? getDate(this.docData.static_created_date)
            : getDate(this.docData.created_at);
    }

    async storeToggleChose(id: string): Promise<void> {
        this.isChosen = await this.CLICK_ON_DOCUMENT_CHOSE_BTN({ docId: id, isChosen: this.isChosen });
    }
}
