






















































































































































































































































































































import { Component, Vue } from 'vue-property-decorator';
import DateTimePicker from '@/components/DateTimePicker.vue';
import moment from 'moment';
import TimePicker from '@/components/TimePicker.vue';
import {
    AllEvents,
    Event as AequoEvent,
    EventSubscriber,
    SubscribeOrUnsubscribeToEvent,
} from '@/types/event';
import graphql from '@/plugins/graphql';
import GetAllEvents from '@/graphql/Events/GetAllEvents.gql';
import getEventsByDate from '@/graphql/Events/getEventsByDate.gql';
import createEvent from '@/graphql/Events/createEvent.gql';
import subscribeToEvent from '@/graphql/Events/subscribeToEvent.graphql';
import unsubscribeFromEvent from '@/graphql/Events/unsubscribeFromEvent.graphql';
import GetLastEdition from '@/graphql/Editions/lastEdition.graphql';
import { Action, Getter } from 'vuex-class';
import { UserType } from '@/types/user';
import LastEditions from '@/components/layouts/LastEditions.vue';
import { endpoint } from '@/helpers/endpoint';
import Slick from 'vue-slick';
import PostsSlider from '@/components/layouts/PostsSlider.vue';
import { EditionsRequestTypes } from '@/types/edition';
import { PostType } from '@/types/post';

export type CreateEvent = {
    date: string;
    time: string;
    title: string;
    description: string;
    image: File | string;
};

@Component({
    components: {
        PostsSlider,
        TimePicker,
        DateTimePicker,
        LastEditions,
        Slick,
    },
})
export default class GetTogether extends Vue {
    private selectedDate = moment().format('YYYY-MM-DD');
    private events: AequoEvent[] = [];
    private posts: PostType[] = [];
    private createEvent: CreateEvent = {
        date: moment().format('YYYY-MM-DD'),
        time: moment().format('HH:mm'),
        title: '',
        description: '',
        image: '',
    };

    @Getter('user/getUser')
    private user: UserType;
    @Action('user/loadUser')
    private loadUser: () => void;

    async upload(data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files[0]) {
            this.createEvent.image = target.files[0];
        } else {
            this.createEvent.image = '';
        }
    }

    private formatDate(date: string): string {
        return moment(date).format('DD MMMM YYYY, HH:mm');
    }

    private getEventImg(event: AequoEvent) {
        if (event.image) {
            return endpoint + event.image.publicUrl;
        } else {
            return 'img/events/1.jpg';
        }
    }

    get avatar(): string {
        if (this.user && this.user.avatar) {
            return `${endpoint}${this.user.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    private getEventAuthorAvatar(event: AequoEvent): string {
        if (event && event.author && event.author.avatar) {
            return `${endpoint}${event.author.avatar.publicUrl}`;
        } else {
            return '../img/masonry-block/2.jpg';
        }
    }

    private subscribed(event: AequoEvent): boolean {
        const userId = this.user.id ? this.user.id : -1;
        return (
            event.users.filter((i: EventSubscriber) => {
                return i.id === userId;
            }).length > 0
        );
    }

    private contactAuthor(event: AequoEvent) {
        window.location.href = `mailto:${event.author.email}`;
    }

    async makeCreateEventRequest(): Promise<void> {
        const dt = `${this.createEvent.date} ${this.createEvent.time}`;
        const event: Record<string, any> = {
            author: { connect: { id: this.user.id } },
            title: this.createEvent.title,
            description: this.createEvent.description,
            body: 'some body',
            date_time: moment(dt).toISOString(true),
            published: true,
        };
        if (this.createEvent.image !== '') {
            event.image = this.createEvent.image;
        }
        try {
            const res = await this.$apollo.mutate({
                mutation: createEvent,
                variables: {
                    data: event,
                },
                context: {
                    hasUpload: true,
                },
            });
            if (res && res.data) {
                await this.loadEvents();
            }
        } catch (e) {
            console.log('Res error: ', JSON.stringify(e));
        }
    }

    async addToCalendar(event: AequoEvent): Promise<void> {
        const res = await graphql<SubscribeOrUnsubscribeToEvent>(
            subscribeToEvent,
            {
                eventID: event.id,
                userID: this.user.id,
            }
        );
        if (res && res.updateEvent && res.updateEvent.id) {
            this.events = this.events.map((e: AequoEvent) => {
                if (e.id === res.updateEvent.id) {
                    e.users = res.updateEvent.users;
                }
                return e;
            });
        }
    }

    async removeFromCalendar(event: AequoEvent): Promise<void> {
        const res = await graphql<SubscribeOrUnsubscribeToEvent>(
            unsubscribeFromEvent,
            {
                eventID: event.id,
                userID: this.user.id,
            }
        );
        if (res && res.updateEvent && res.updateEvent.id) {
            this.events = this.events.map((e: AequoEvent) => {
                if (e.id === res.updateEvent.id) {
                    e.users = res.updateEvent.users;
                }
                return e;
            });
        }
    }

    async getByDate(dateStr: string): Promise<void> {
        const date = moment(dateStr);
        const start = date.startOf('date').toISOString(true);
        const end = date.endOf('date').toISOString(true);
        const resp = await graphql<AllEvents>(getEventsByDate, {
            startDate: start,
            endDate: end,
        });
        if (resp && resp.allEvents) {
            this.events = resp.allEvents;
        }
    }

    async loadEvents(): Promise<void> {
        const resp = await graphql<AllEvents>(GetAllEvents, {
            now: moment().toISOString(true),
        });
        if (resp && resp.allEvents) {
            this.events = resp.allEvents;
        }
    }

    async getLastEdition(): Promise<void> {
        const editionRequest = await graphql<EditionsRequestTypes>(
            GetLastEdition
        );
        if (
            editionRequest &&
            editionRequest.allEditions &&
            editionRequest.allEditions.length > 0
        ) {
            this.posts = editionRequest.allEditions[0].posts;
        }
    }

    async created(): Promise<void> {
        await this.loadEvents();
        await this.getLastEdition();

        if (!this.user) {
            await this.loadUser();
        }
    }
}
