








import { Component, Vue } from 'vue-property-decorator';
import { Getter, Mutation } from 'vuex-class';
import { UserType } from '@/types/user';
import slideshow from 'vue-gallery-slideshow';

import getToken from '@/requests/getInstagramAuthToken';
import getFullToken from '@/requests/getInstagramAuthFullToken';
import { InstagramAuthLoginConfig } from '@/types/front-data-type/instagram/instagramAuthLoginConfig';
import { saveToken } from '@/requests/tokenApi';

@Component({
    components: { slideshow },
})
export default class Gallery extends Vue {
    @Mutation('appState/SET_NAVBAR_STATUS')
    private setNavStatus: (status: boolean) => void;
    @Mutation('user/SET_USER')
    private setUser: (user: UserType) => void;
    @Getter('appState/getNavbarStatus')
    private navStatus: boolean;
    @Getter('user/getUser')
    private getUser: UserType;

    private code = '';

    private showUploadInput = true;
    private showDialog = false;
    private uploadedImagesIds: Record<string, unknown>[] = [];
    private uploadedImagesUrls: string[] = [];
    private postGallery = {
        title: '',
        order: '0',
    };

    get instagramAuthLoginConfig(): InstagramAuthLoginConfig {
        return {
            token: {
                url: `https://api.instagram.com/oauth/access_token`,
                clientId: '570242227554172',
                clientSecret: '484d4ac26376072366b6ad3771af57c8',
                grantType: 'authorization_code',
                redirectUri: 'https://lifestyle.aequo.ua/instagram-auth',
            },
            fullToken: {
                url: `https://graph.instagram.com/access_token`,
                grantType: 'ig_exchange_token',
                clientId: '570242227554172',
                clientSecret: '484d4ac26376072366b6ad3771af57c8',
            },
        };
    }

    get instagramAuthUrl(): string {
        return `https://api.instagram.com/oauth/authorize?client_id=570242227554172&redirect_uri=https%3A%2F%2Flifestyle.aequo.ua%2Finstagram-auth&scope=user_profile,user_media&response_type=code`;
    }

    public async setToken(): Promise<void> {
        if (this.code && window) {
            const tokenRequest = await getToken(
                this.code,
                this.instagramAuthLoginConfig
            );
            if (tokenRequest && tokenRequest.access_token) {
                const fullTokenRequest = await getFullToken(
                    tokenRequest.access_token,
                    this.instagramAuthLoginConfig
                );
                if (fullTokenRequest && fullTokenRequest.access_token) {
                    await saveToken(fullTokenRequest.access_token);
                }
            }
            await this.$router.push('/');
        }
    }

    async mounted(): Promise<void> {
        await this.setToken();
    }
    async created(): Promise<void> {
        this.code = this.$route.query.code as string;
        this.setNavStatus(false);
    }

    dropDataToDefault(): void {
        this.showUploadInput = true;
        this.showDialog = false;
        this.uploadedImagesIds = [];
        this.postGallery.title = '';
        this.postGallery.order = '0';
        this.uploadedImagesUrls = [];
    }
}
