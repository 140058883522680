



























import { Component, Vue } from "vue-property-decorator";
import { Getter, Mutation } from "vuex-class";
import {UserType } from "@/types/user";
import CreateGalleryItem from "@/graphql/Galleries/createGalleryItem.gql";
import graphql from "@/plugins/graphql";
import createPostGallery from "@/graphql/PostGalleries/createPostGallery.gql";
import { PostGalleryCreateRequestType } from "@/types/post";
import slideshow from "vue-gallery-slideshow"
import {endpoint} from "@/helpers/endpoint"

@Component({
    components: {slideshow}
})
export default class Gallery extends Vue {
    @Mutation('appState/SET_NAVBAR_STATUS')
    private setNavStatus: (status: boolean) => void;
    @Mutation('user/SET_USER')
    private setUser: (user: UserType) => void;
    @Getter('appState/getNavbarStatus')
    private navStatus: boolean;
    @Getter('user/getUser')
    private getUser: UserType;

    private endpoint = endpoint
    private showUploadInput = true
    private showDialog = false
    private uploadedImagesIds: Record<string, unknown>[] = []
    private uploadedImagesUrls: string[] = []
    private index: number | null = null;
    private postGallery = {
        title: '',
        order: '0'
    }

    get validImageUrls(): string[] {
        return  this.uploadedImagesUrls.map((url) => {
            return this.endpoint + url
        })
    }

    async created(): Promise<void> {
        this.setNavStatus(false)
    }

    async uploadFiles (data: Event): Promise<void> {
        const target = data.target as HTMLInputElement;
        if (target && target.files && target.files.length > 0) {
            const files: Record<string, unknown>[] = [];
            Array.from(target.files).forEach(file => {
                const galleryItem = {
                    data: {
                        image: file
                    }
                }
                files.push(galleryItem)
            });
            for(const file of files) {
                try {
                    const res = await this.$apollo.mutate({
                        mutation: CreateGalleryItem,
                        variables: file,
                        context: {
                            hasUpload: true
                        }
                    })
                    this.uploadedImagesUrls.push(res.data.createGallery.image.publicUrl)
                    this.uploadedImagesIds.push({id: res.data.createGallery.id})
                } catch (e) {
                    localStorage.removeItem('galleryAuthToken')
                    this.$router.push('/gallery/login')
                }
            }
            this.showDialog = true
            this.showUploadInput = false
        }
    }

    async createPostGallery(): Promise<void> {
        if (this.postGallery.title.length < 1 || this.postGallery.order.length < 1) {
            alert('Заполните название и порядковый номер!')
        } else {
            const data = {
                images: {connect: this.uploadedImagesIds},
                title: this.postGallery.title,
                order: parseInt(this.postGallery.order)
            }
            console.log('data', data);
            const res = await graphql<PostGalleryCreateRequestType>(
                createPostGallery,
                { data })
            if (res!.createPostGallery) {
                this.dropDataToDefault()
            } else {
                localStorage.removeItem('galleryAuthToken')
                this.$router.push('/gallery/login')
            }
        }
    }

    dropDataToDefault():void {
        this.showUploadInput = true;
        this.showDialog = false;
        this.uploadedImagesIds = [];
        this.postGallery.title = '';
        this.postGallery.order = '0';
        this.uploadedImagesUrls = []
    }
}
