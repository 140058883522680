<template>
    <svg
        width="26"
        height="24"
        viewbox="-3 0 32 24"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M24.2589 15.3911C25.4196 13.9187 26 12.314 26 10.577C26 8.84 25.4196 7.23529 24.2589 5.76285C23.0982 4.29041 21.5216 3.12744 19.529 2.27393C17.5365 1.42041 15.3601 0.993652 13 0.993652C10.6399 0.993652 8.46357 1.42041 6.47101 2.27393C4.47845 3.12744 2.90179 4.29041 1.74108 5.76285C0.580365 7.23529 0 8.84 0 10.577C0 12.0744 0.437694 13.4769 1.31307 14.7847C2.18844 16.0924 3.38544 17.1905 4.90404 18.0789C4.79764 18.4782 4.6719 18.8576 4.52681 19.2169C4.38172 19.5763 4.25114 19.8708 4.13507 20.1004C4.019 20.33 3.86182 20.5846 3.66353 20.8641C3.46524 21.1436 3.3153 21.3408 3.21374 21.4556C3.11217 21.5704 2.94532 21.76 2.71318 22.0246C2.48103 22.2891 2.33111 22.4613 2.2634 22.5412L2.14733 22.6759L2.04577 22.8032L1.95871 22.938C1.91035 23.0128 1.88859 23.0603 1.89342 23.0802C1.89826 23.1002 1.88859 23.1501 1.86441 23.23C1.84022 23.3098 1.84264 23.3697 1.87166 23.4097V23.4246C1.91035 23.5943 1.98773 23.7316 2.1038 23.8364C2.21987 23.9412 2.35046 23.9936 2.49554 23.9936H2.56809C3.19681 23.9138 3.74816 23.804 4.22212 23.6642C6.75635 22.9954 8.98106 21.7875 10.8962 20.0405C11.6217 20.1204 12.3229 20.1603 13 20.1603C15.3601 20.1603 17.5365 19.7335 19.529 18.88C21.5216 18.0265 23.0982 16.8635 24.2589 15.3911Z"
            fill="#4383FF"
        />
    </svg>
</template>

<script>
export default {
    name: 'ArCommentIcon',
};
</script>

<style scoped></style>
