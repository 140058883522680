/* eslint-disable @typescript-eslint/ban-ts-comment */
import Vue from 'vue'
import App from './App.vue'
import router from './router'
import { ApolloClient } from "apollo-client";
import { ApolloLink, concat } from '@apollo/client/core';
import { InMemoryCache } from "apollo-cache-inmemory";
import VueApollo from "vue-apollo";
import moment from "moment";
import { createUploadLink,  } from 'apollo-upload-client'
import store from './store'

moment.locale('uk')


const authApolloMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  operation.setContext({
    headers: {
      authorization: `Bearer ${localStorage.getItem('galleryAuthToken')}`,
    }
  });
  return forward(operation);
})

const link = ApolloLink.from([
  createUploadLink({
    uri: process.env.VUE_APP_GRAPHQL_HTTP
  })
]);

const apolloClient = new ApolloClient({
  // @ts-ignore
  link: concat(authApolloMiddleware, link),
  cache: new InMemoryCache(),
  connectToDevTools: true,
});
const apolloProvider = new VueApollo({
  defaultClient: apolloClient
});

Vue.use(VueApollo);

Vue.config.productionTip = false

new Vue({
  router,
  apolloProvider,
  store,
  render: h => h(App)
}).$mount('#app')
