






















































































































































import { Component, Prop, Vue } from 'vue-property-decorator';
import Slick from 'vue-slick';
import { DocumentType } from '@/types/document';

import BookSlider from '@/components/layouts/BookSlider.vue';

@Component({
    components: {
        Slick,
        BookSlider,
    },
})
export default class BooksSlider extends Vue {
    @Prop({
        default: () => {
            return [];
        },
    })
    readonly books: DocumentType[];

    next(): void {
        this.$refs.slider.next();
    }

    prev(): void {
        this.$refs.slider.prev();
    }

    $refs!: {
        slider: Slick;
    };

    private slickOptions = {
        infinite: true,
        // slidesToShow: 7,
        slidesToScroll: 1,
        autoplay: false,
        autoplaySpeed: 4000,
        focusOnSelect: true,
        pauseOnHover: true,
        centerMode: true,
        variableWidth: true,
        arrow: true,
        responsive: [
            {
                breakpoint: 540,
                settings: {
                    centerMode: false,
                    variableWidth: false,
                },
            },
        ],
    };
}
