import { defaultUser, UserType } from '@/types/user';
import { defaultFile, FileType } from '@/types/base/file';
import { defaultEdition, EditionType } from '@/types/edition';
import clone from '@/helpers/clone';
import { TagTopPost } from '@/types/tagTopPost';
import { GalleryItem } from '@/types/gallery';

export type PostType = {
    author: UserType;
    display_date: string;
    baseImage: FileType;
    thumbnail: FileType;
    comments: CommentType[];
    content: string;
    created_at: string;
    edition: EditionType;
    id: string;
    number: string;
    tag: TagType;
    title: string;
    likes: UserType[];
    views: UserType[];
    galleries: PostGalleryType[];
};

export type PostGalleryType = {
    id: string;
    content: string;
    images: GalleryItem[];
};

export type CommentType = {
    author: UserType;
    childComments?: CommentType[];
    id?: string;
    parent?: Comment;
    post: PostType | string;
    text: string;
    createdAt?: string;
};

export type TagType = {
    id: string;
    name: string;
    posts: PostType[];
    banner: string;
    description: string;
};

export const defaultTag: TagType = {
    id: '',
    name: '',
    posts: [],
    banner: '',
    description: '',
};

export type AllPostsRequest = {
    allPosts: PostType[];
};

export type TagRequestType = {
    Tag: TagType;
};

export type TagLatestPost = {
    allPosts: [TagTopPost];
};

export type TagsRequestTypes = {
    allTags: TagType[];
};

export type PostRequestType = {
    Post: PostType;
};

export type PostErrorReportType = {
    createPostErrorReport: {
        id: number;
    };
};

export type LikeRequestType = {
    updatePost: PostType;
};

export type PostGalleryCreateRequestType = {
    createPostGallery: PostType;
};

export type WatchRequestType = {
    updatePost: PostType;
};

export type isLikedRequestType = {
    allPosts: PostType[];
};

export type isWatchedRequestType = {
    allPosts: PostType[];
};

export type PostsRequestType = {
    allPosts: PostType[];
};

export type CommentsRequestType = {
    allComments: CommentType[];
};

export type CreateCommentType = {
    createComment: CommentType;
};

export const defaultTagLatestPost: TagTopPost = {
    id: '',
    views: [],
    title: '',
    baseImage: {
        publicUrl: '',
    },
    comments: [],
    likes: [],
};

export const defaultComment: CommentType = {
    text: '',
    post: '',
    author: clone(defaultUser),
    parent: undefined,
    childComments: undefined,
};

export const defaultPost: PostType = {
    display_date: '',
    author: clone(defaultUser),
    baseImage: clone(defaultFile),
    thumbnail: clone(defaultFile),
    comments: [],
    content: '',
    created_at: '',
    edition: clone(defaultEdition),
    id: '',
    number: '',
    tag: clone(defaultTag),
    title: '',
    likes: [],
    views: [],
    galleries: [],
};
