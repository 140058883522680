






















import { Component, Prop, Vue } from 'vue-property-decorator';

import ArAvatar from '@/components/layouts/icons/ArAvatar.vue';
import { PostType } from '@/types/post';
import { TagTopPostCommentLike } from '@/types/tagTopPost';

@Component(
    {
        name: 'UserListTooltip',
        components: {
            ArAvatar
        }
    }
)
export default class UserListTooltip extends Vue {
    @Prop({ default: [] }) readonly userList: PostType[] | TagTopPostCommentLike[];
}
