<template>
    <svg
        width="32"
        height="18"
        viewbox="0 0 32 18"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M0.892927 8.58978C16.2267 -9.43243 30.9233 8.36782 31.1045 8.58758C31.3023 8.81886 31.3102 9.16575 31.1084 9.40689C31.0655 9.45826 16.3093 27.5052 0.907815 9.42638C0.695399 9.19462 0.683976 8.83545 0.892927 8.58978Z"
            fill="#4383FF"
        />
        <path
            d="M15.7031 4C17.0017 4 18.1775 4.52644 19.0286 5.37757C19.8797 6.22868 20.4062 7.40448 20.4062 8.70309C20.4062 10.0017 19.8797 11.1775 19.0286 12.0286C18.1775 12.8797 17.0017 13.4062 15.7031 13.4062C14.4044 13.4062 13.2287 12.8797 12.3775 12.0286C11.5264 11.1775 11 10.0017 11 8.70309C11 7.40447 11.5264 6.22868 12.3775 5.37757C13.2287 4.52644 14.4045 4 15.7031 4Z"
            fill="white"
        />
    </svg>
</template>

<script>
export default {
    name: 'ArViewsIcon',
};
</script>

<style scoped></style>
