










import { Component, Prop, Vue } from 'vue-property-decorator';

@Component({ name: 'Avatar' })
export default class Avatar extends Vue {
    @Prop({ default: null }) readonly avatar: any;
    private useFallBack = false;

    get basicUrl(): string | undefined {
        return process.env.VUE_APP_STATIC_URL;
    }

    get avatarSrc(): string {
        if (!this.useFallBack) {
            return this.avatar && this.avatar.publicUrl
                ? `${this.basicUrl}${this.avatar.publicUrl}`
                : require('../../../assets/images/avatar-default.svg');
        } else {
            return require('../../../assets/images/avatar-default.svg');
        }
    }
}
