import axios from 'axios';

export async function saveToken(token: string): Promise<boolean> {
    try {
        const url = process.env.VUE_APP_API_ENDPOINT + '/instagram-key';
        const response = await axios.post(url, {
            api_key: token,
        });
        console.log('resp', response);
        return response.data.success;
    } catch (e) {
        console.log('error', e.response);
        return false;
    }
}

export async function getToken(): Promise<string | false> {
    try {
        const url = process.env.VUE_APP_API_ENDPOINT + '/instagram-key';
        const response = await axios.get(url);
        console.log('resp', response);
        if (response.data.success && response.data.api_key) {
            return response.data.api_key;
        }
        return false;
    } catch (e) {
        console.log('error', e.response);
        return false;
    }
}
